require('regenerator-runtime/runtime')
const NextI18Next = require('next-i18next').default;

const i18n = new NextI18Next({
	otherLanguages: ['tr'],
	allLanguages: ['en', 'tr'],
	fallbackLng: 'en',
	defaultLanguage: 'en',
	ignoreRoutes: ['/_next', '/static', '/rest', '/auth'],
	localeSubpaths: "foreign",
	defaultNS: 'common',
	browserLanguageDetection: true,
	serverLanguageDetection: true,
});

module.exports = i18n;
module.exports.appWithTranslation = i18n.appWithTranslation;
module.exports.withNamespaces = i18n.withNamespaces;
module.exports.i18n = i18n.i18n;
module.exports.Link = i18n.Link;
module.exports.Router = i18n.Router;
